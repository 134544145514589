<template>
  <nav>
    <!--    DESKTOP MENU-->
    <div class="nav-container desktop" v-if="!isMobile">
      <router-link to="/" class="nav-link" exact>Home</router-link>
        <router-link class="nav-link" @click.native="showWomenDesktop = false" @mouseover.native="showWomenDesktop = true" @mouseleave.native="showWomenDesktop = false" :to="{name : 'women', params: { womenName: 'nutsa', womenId: 1 } }">
        Women
      </router-link>
      <router-link to="/project" class="nav-link">Foreword</router-link>
      <router-link to="/going-further" class="nav-link">Going further</router-link>
      <a class="nav-link" href="https://fineartamerica.com/profiles/eva-paijens/shop" target="_blank">Shop</a>
      <router-link to="/contact" class="nav-link">Contact</router-link>
    </div>
    <!--    MOBILE MENU-->
    <div class="nav-container mobile" v-if="isMobile">
      <transition name="menu">
        <div class="mobile-menu" v-show="isMenuOpen">
          <router-link to="/" class="nav-link" exact>Home</router-link>
          <router-link :to="{name : 'women', params: { womenName: 'nutsa', womenId: 1 } }" class="nav-link nospace">
            Women
          </router-link>
          <router-link v-for="item in women"
                       :to="{name : 'women', params: { womenName: item.name.toLowerCase(), womenId: item.order } }"
                       class="nav-sublink" :key="item.index">{{item.name}}
          </router-link>
          <router-link to="/project" class="nav-link space">Foreword</router-link>
          <router-link to="/going-further" class="nav-link">Going further</router-link>
          <a class="nav-link" href="https://fineartamerica.com/profiles/eva-paijens/shop" target="_blank">Shop</a>
          <router-link to="/contact" class="nav-link">Contact</router-link>
        </div>
      </transition>
      <div class="burger" @click="toggleMenu" :class="{'transmuted' : isMenuOpen}">
        <span></span>
      </div>
    </div>
    <div class="womenMenu" :class="{'show' : showWomenDesktop}" @mouseup="showWomenDesktop = false"
         @mouseover="showWomenDesktop = true"
         @mouseleave="showWomenDesktop = false">
      <carousel :perPageCustom="[[768, 10]]" :paginationEnabled="false" :scrollPerPage="true">
        <slide v-for="item in women" :key="item.index">
          <div class="pic"
               :style="{'background-image': 'url(' + require('@/assets/img/carroussel/' + item.pic) + ')'}"></div>
          <div class="bg"></div>
          <div class="content">
            <div class="story-index">Story {{item.order}}</div>
            <h2 :style="{'background-color':'#' + item.color}">{{item.name}}</h2>
          </div>
          <router-link class="carroussel-link"
                       :to="{name : 'women', params: { womenName: item.name.toLowerCase(), womenId: item.order } }">
          </router-link>
        </slide>
      </carousel>
    </div>
  </nav>
</template>

<script>
import { data } from '@/data';

export default {
  name: 'NavBar',

  props: {
    isMobile: Boolean,
  },

  methods: {
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    }
  },

  computed: {},

  data () {
    return {
      women: data,
      showWomenDesktop: false,
      isMenuOpen: false,
    }
  },

  watch: {
    '$route' () {
      this.isMenuOpen = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .menu-enter-active, .menu-leave-active {
    transition: all .5s;
  }

  .menu-enter, .menu-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    transform: translateY(-30%);
  }

  #app {
    .mobile {

    }
  }

  nav {
    font-family: 'Couture', Helvetica, Arial, sans-serif;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 10;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);

    .nav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;
      background: white;
      width: 100%;
      height: 100%;

      &.mobile {
        .nav-link {
          margin-bottom: 3rem;
        }

        .nav-sublink {
          font-size: 1.6rem;
          color: black;
          padding-left: 3rem;
          margin-bottom: 1.5rem;
        }
      }

      .nospace {
        margin-bottom: 2rem !important;
      }

      .space {
        margin-top: 3rem;
      }

      &.desktop {
        .nav-link {
          height: 100%;
          line-height: 50px;
          z-index: 1;
          font-size: 1.4rem;
          padding: 0 3rem;

          &:hover {
            color: $green
          }
        }
      }
    }

    .nav-link {
      font-size: 2rem;
      position: static;
      color: black;
      cursor: pointer;
      z-index: 1;

      &.router-link-active {
        color: $green
      }

      &:hover {
        color: $green
      }
    }

    .mobile-menu {
      position: fixed;
      background: white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding-bottom: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 100px;
      padding-left: 40px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
      overflow: scroll;
    }

    .burger {
      width: 50px;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;

      span {
        display: block;
        width: 26px;
        height: 3px;
        background: black;
        position: relative;
        transition: all 200ms ease-in-out;

        &:after, &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: black;
          position: absolute;
          transition: all 200ms ease-in-out;
        }

        &:before {
          top: -8px;
        }

        &:after {
          top: 8px;
        }
      }

      &.transmuted {

        span {
          background: white;

          &:before {
            top: 0;
            transform: rotate(45deg);
          }

          &:after {
            top: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }

    .womenMenu {
      position: absolute;
      left: 0;
      width: 100%;
      top: 100%;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-100%);
      transition: all 200ms ease-in-out;
      z-index: -1;

      &.show {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
      }

      .VueCarousel-slide {
        height: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        overflow: hidden;
        transition: all 100ms ease-in-out;

        @media (min-width: 1600px) {
          min-height: 300px;
        }

        &:nth-child(3) {
          .pic {
            background-position: top;
          }
        }

        &:hover {
          .pic {
            transform: scale(1.12);
          }
        }

        .pic {
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          transition: all 200ms ease-in-out;
        }

        &:hover {

        }

        .carroussel-link {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          font-family: 'Couture', Helvetica, Arial, sans-serif;
          padding: 1rem 1rem 1rem 0;
          color: $green;
          transition: all 100ms ease-in-out;

          &:hover {
            color: white;
            transform: translateX(3px);
          }
        }

        .bg {
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
          position: absolute;
          height: 60%;
          bottom: 0;
          width: 100%;
          z-index: 0;
        }

        .content {
          pointer-events: none;
          flex: 0 0 auto;
          padding: 2rem;
          position: relative;
          z-index: 1;

          .story-index {
            color: white;
            text-transform: uppercase;
            font-family: 'Couture', Helvetica, Arial, sans-serif;
            font-size: 1rem;
            line-height: 1rem;
          }

          h2 {
            display: inline-block;
            color: white;
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-family: 'Couture', Helvetica, Arial, sans-serif;
            padding: .8rem .8rem .2rem .8rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
